
const routes = [
    {
      path: "/",
      component: () => import('@/views/Template'),
      children: [
        {
          name: 'Home',
          path: '',
          component: () => import('@/views/Home'),
        },
        {
          name: 'Login',
          path: 'login',
          component: () => import('@/views/Login'),
        },
        {
          name: 'Authorize',
          path: 'oauthconfirm',
          component: () => import('@/views/Authorize'),
        },
      ]
    },
    {
      path: "*",
      component: () => import('@/views/NotFound.vue'),
    }
  ];

export default routes;
