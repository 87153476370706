import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import store from './store';
import router from './router';
import globalFunctions from "./plugins/global-functions";
import './plugins/toasted';
import './plugins/axios';

Vue.config.productionTip = false;

/* install global functions */
Vue.use(globalFunctions);

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app');
