import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#066F6A", //headers
        secondary: "#D1AE6C", // text
        accent: "#04524e", // edit
        success: "#4CAF50", // succes - add
        info: "#2196F3", // info - duplicate
        warning: "#FB8C00", // warning - waiting
        error: "#FF5252", // error - delete
        inspect: "#C0C0C0", // something
      },
      dark: {
        primary: "#4D4D4D",  //headers
        secondary: "#D1AE6C", // text
        accent: "#066F6A", // edit
        success: "#4CAF50", // succes - add
        info: "#2196F3", // info - duplicate
        warning: "#FB8C00", // warning - waiting
        error: "#FF5252", // error - delete
        inspect: "#4D4D4D", // something
      }
    }
  }
});
