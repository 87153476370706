import Vue from 'vue'
import Vuex from 'vuex'

import * as packageInfo from '@/../package.json';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    packageVersion: packageInfo.version
  },
  mutations: {
  },
  actions: {
  },
  getters: {
    appVersion(state) {
        return state.packageVersion
    },
  },
  modules: {
  }
})
