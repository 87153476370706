<template>
  <router-view />
</template>

<script>
  export default {
    name: 'App',

    created() {
      console.log('Versao:', this.$store.getters.appVersion)
    }
  }
</script>

<style>
html {
  overflow-y: hidden !important;
  overflow-x: auto !important;
}
</style>