const action = [
  {
    text: 'Fechar',
    onClick: (_, toastObject) => {
      toastObject.goAway(0);
    }
  }
]

export default {
  install(Vue) {
    Vue.prototype.$alertSuccess = (msg, timeout = 5000) => {
      Vue.toasted.show(msg, {
        action,
        theme: "toasted-primary",
        position: "top-right",
        className: "success-toast-class",
        duration: timeout,
      });
    };

    Vue.prototype.$alertError = (msg, timeout = 5000) => {
      Vue.toasted.show(msg, {
        action,
        theme: "toasted-primary",
        position: "top-right",
        className: "error-toast-class",
        duration: timeout,
      });
    };

    Vue.prototype.$alertInfo = (msg, timeout = 5000) => {
      Vue.toasted.show(msg, {
        action,
        theme: "toasted-primary",
        position: "top-right",
        className: "info-toast-class",
        duration: timeout,
      });
    };
  }
}
