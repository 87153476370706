import axios from 'axios'

const httpApi = axios.create({baseURL: process.env.VUE_APP_API})
const httpAuth = axios.create({baseURL: process.env.VUE_APP_AUTH})

function axiosBase(url) { return axios.create({baseURL: url}) }

export default {
  httpApi,
  httpAuth,
  axiosBase
}